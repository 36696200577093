.input1,
.input2,
.input3,
.input4 {
  width: 133%;
  height: 49%;
  padding: 0px;
  text-align: center;
  font-weight: 500;
}
.TermsConditionLoader {
  position: relative;
  top: 50%;
  margin-top: 15%;
  margin-bottom: 10%;
  left: 45%;
  font-size: 130%;
}
.TermsConditionContainer{
  margin-top:110px
}
.CheckBoxContainerSignUp {
  display: flex;
  flex-direction: row;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
    white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.ErrorTextSignup {
  text-align: left;
  margin-left: 13%;
}
.AcceptErrorTextSignup {
  margin-left: 25%;
  width: 102%;
}
.signTextLanding {
  margin-left: 7%;
  position: relative;
  left: 19%;
}
.InputBoxSignUp {
  width: 76%;
  margin-left: 11%;
}

.text {
  width: 64%;
  font-size: 18px !important;
}

#rememberMe {
  position: relative;
  left: 318%;
}
.write_here {
  border-top: 0px !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0%;
  border-bottom-style: 5px solid black !important;
  width: 100%;
}

.select_shared_source {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='#FF6838'  height='24' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border: 1px solid black;
  border-radius: 25px !important;
}

/* .HearAbout_Write_text{
    border-top:none !important;
    border-left: none!important;
    border-right: none!important;
    border-radius: 0%;
  } */

.HearAbout_Write_text:focus {
  border-color: #ced4da;
  /* default B5 color or set your own color*/
  border-top: none !important;
  /* if you want to remove borders at all*/
  border-left: none !important;
  /* if you want to remove borders at all*/
  border-right: none !important;
  /* if you want to remove borders at all*/
  border-radius: 0%;
  outline: none !important;
  box-shadow: none !important;
}

.write_here_text {
  color: #ff6838;
}

.get_otp {
  font-size: small;
  font-weight: 600;
  border-radius: 60px;
}

.text small {
  margin-left: 25px;
  font-size: 17px;
}

.TimerCountValue {
  text-align: center;
}

.signup-container {
  margin-left: 2%;
}

/* .signup-container div {
  width: 75%;
  margin: auto;
  margin-top: 4%;
} */

.signup-container select {
  width: 75%;
  margin: auto;
  margin-top: 4%;
}

.hear-abt {
  margin-left: 12%;
}

.otp_inputs_Login input {
  border: 1px solid black !important;
}

@media only screen and (min-width: 376px) and (max-width: 769px) {
  .change_text {
    margin-left: 6%;
  }
  .input-group {
    margin-left: 23% !important;
  }
  .get_otp_button_login {
    margin-left: 22% !important;
  }
  .otp {
    margin-left: 8% !important;
    width: 121%;
    margin-top: 4%;
  }
  .otp_inputs_Login {
    margin-left: 42% !important;
  }
  .verify {
    margin-left: 42% !important;
  }
  .TimeLeftText {
    text-align: center;
    margin-left: 39%;
    margin-top: 11%;
  }
  .signuppotp {
    margin-left: 1% !important;
  }
  .signupotpText {
    margin-left: -11% !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .text {
    width: 74% !important;
  }
  .signTextLanding {
    margin-left: 0% !important;
    position: relative;
    left: 20% !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  #rememberMe {
    margin-left: 12% !important;
    margin-right: 8%;
    font-size: 90%;
    left: 376%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #rememberMe {
    margin-right: 2% !important;
    left: 358%;
    font-size: 88%;
    margin-top: 29%;
  }
  .css-i44wyl {
    margin-right: -28px;
  }
  .verify {
    margin-left: -9% !important;
  }
  .ConditionPrivacyTerms {
    font-size: 98%;
  }
  .termsConditionLogin {
    margin-left: 1% !important;
  }
  .CheckBoxContainerSignUp {
    display: flex;
    flex-direction: row;
    width: 83%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1444px) {
  .ConditionPrivacyTerms {
    margin-left: 13%;
    font-size: 98%;
  }

  #rememberMe {
    margin-top: 25%;
    margin-right: 3%;
    left: 380%;
    font-size: 90%;
  }
  .termsConditionLogin {
    margin-left: 2% !important;
  }
  .text {
    width: 81% !important;
  }
  .signTextLanding {
    margin-left: 27% !important;
    position: relative;
    left: 24%;
  }
  .signTextLanding {
    margin-left: 13% !important;
    position: relative;
    left: 9% !important;
  }
}

@media only screen and (min-width: 1445px) and (max-width: 1600px) {
  .termsConditionLogin {
    margin-left: 1% !important;
  }
  .ConditionPrivacyTerms {
    margin-left: 7%;
    font-size: 98%;
  }
}
@media screen and (min-width: 797px) and (max-width: 992px) {
  .signTextLanding {
    margin-left: 3%;
  }
  .ConditionPrivacyTerms {
    font-size: 98%;
    margin-left: 3%;
  }
  .termsConditionLogin {
    margin-left: 1% !important;
  }
}
@media screen and (min-width: 487px) and (max-width: 576px) {
  .termsConditionLogin {
    margin-left: 1% !important;
  }
  #rememberMe {
    position: relative;
    left: 260%;
    font-size: 81%;
    margin-top: 36%;
  }
  .ConditionPrivacyTerms {
    font-size: 86%;
  }
}
@media screen and (min-width: 487px) and (max-width: 576px) {
  .signTextLanding {
    margin-left: 0% !important;
    position: relative;
    left: 22%;
  }

  .signUpTerms {
    width: 86% !important;
    margin-left: -6% !important;
  }
  .text {
    width: 78% !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 796px) {
  .text {
    width: 80%;
  }
  #rememberMe {
    font-size: 84%;
    left: 290%;
    margin-top: 33%;
  }
  .signTextLanding {
    margin-left: 13% !important;
    position: relative;
    left: 3%;
  }
  .termsConditionLogin {
    margin-left: 1% !important;
  }
  .ConditionPrivacyTerms {
    font-size: 98%;
    margin-left: 13%;
  }
}
@media screen and (min-width: 450px) and (max-width: 486px) {
  #rememberMe {
    left: 259%;
    font-size: 86%;
    margin-top: 34%;
  }
  .text small {
    margin-left: 25px;
    font-size: 17px;
  }
  .signTextLanding {
    margin-left: 0% !important;
    left: 17%;
    font-size: 82% !important;
  }
  .signUpTerms {
    width: 75% !important;
    margin-left: 0% !important;
  }
  .ConditionPrivacyTerms {
    font-size: 93%;
  }
  .termsConditionLogin {
    margin-left: 1% !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .signTextLanding {
    left: 13.5%;
  }
}
