.otptext {
  font-weight: 500;
  text-align: center;
  font-size: 1vw;
}

.otp {
  text-align: "center";
  font-size: "130%";
  font-weight: 700;
}

.inputone,
.inputtwo,
.inputthree,
.inputfour {
  width: 141%;
  height: 63%;
  text-align: center;
  font-weight: 500;
  padding: 2px;
}

.inputone::before,
.inputtwo::before,
.inputthree::before,
.inputfour::before {
  border-bottom: 1px solid black !important;
}

.swal-button {
  background-color: #ff6838;
  color: white;
}

.swal-button:hover {
  background-color: #ff6838;
  color: white;
}

.borderTextBox {
  border-bottom: 1px solid black !important;
  margin: 2px;
  position: relative;
  top: -5px;
  left: 5px;
}

.LoginInputText {
  display: flex;
  /* align-items: center; */
  /* padding: 0.376rem 0.5rem; */
  font-size: 1vw !important;
  text-align: center;
  /* padding-bottom: 6px!important; */
  font-weight: 400 !important;
  margin-top: 0px !important;
  padding-top: 4px !important;
  margin-bottom: 3px !important;
  padding-right: 3px !important;
  /* line-height: 0.3; */
  height: 2.45vw !important;
  color: #212529 !important;
  text-align: center !important;
  white-space: nowrap !important;
  background-color: white !important;
  border: 1px solid black !important;
  border-radius: 0.175rem !important;
}

.getNumber {
  width: 100% !important;
  color: white !important;
  background-color: #ff6838 !important;
}

.getMobNumber {
  width: 100% !important;
  font-size: 97% !important;
}

.verify {
  color: white !important;
  background-color: #ff6838 !important;
}

/* Mobile Number Input Field - starts*/

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

/* input {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  display: table-cell;
  border: 1px solid #ccc;
  margin: 0;
  font-size: 11px !important;
} */

.suffix input {
  border-radius: 4px 0px 0px 4px;
}

.suffix .input-group-addon {
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}

.prefix input {
  border-radius: 0px 4px 4px 0px;
}

.prefix .input-group-addon {
  border-right: 0;
  border-radius: 4px 0px 0px 4px;
}

.presuffix input {
  border-radius: 0px;
}

.input-group-addon.prefix {
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
}

.input-group-addon.suffix {
  border-radius: 0px 4px 4px 0px;
  border-left: 0;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 60%;
  margin: auto;
}

.error-style {
  color: red;
  font-size: "1vw";
  text-align: center;
}

.get-otp-btn {
  width: 63%;
  margin: 4% auto;
}

.desc-login {
  margin-top: 3%;
}

.otpformat div {
  justify-content: center;
}

/* Mobile Number Input Field - ends*/

@media (min-width: 320px) and (max-width: 376px) {
  .css-1bn53lx {
    font-size: 0.8rem !important;
  }
  .get-otp-btn {
    width: 63%;
    margin: 4% auto;
    margin-left: 25px;
  }
  .input-group {
    position: relative;
    display: inline-table;
    border-collapse: separate;
    width: 73%;
    margin: auto;
    margin-left: 15px;
  }
  /* input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    border: 1px solid #ccc;
    margin: 0;
    font-size: 12px !important;
  } */
  .error-style {
    color: red;
    font-size: 80% !important;
    text-align: center;
    margin-left: -25%;
  }
  .otpformat div {
    justify-content: center;
    margin-left: 20%;
  }
  .otp {
    text-align: center;
    font-size: "130%";
    font-weight: 700;
    margin-left: -19%;
    width: 156%;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .get-otp-btn {
    width: 63%;
    margin: 4% auto;
    margin-left: 37px;
  }
  .input-group {
    position: relative;
    display: inline-table;
    border-collapse: separate;
    width: 61%;
    margin: auto;
    margin-left: 62px;
  }
  /* input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    border: 1px solid #ccc;
    margin: 0;
    font-size: 15px !important;
  } */
}

@media (min-width: 451px) and (max-width: 567px) {
  .get-otp-btn {
    width: 63%;
    margin: 4% auto;
    margin-left: 37px;
  }
  .input-group {
    position: relative;
    display: inline-table;
    border-collapse: separate;
    width: 60%;
    margin: auto;
    margin-left: 62px;
  }
  /* input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    border: 1px solid #ccc;
    margin: 0;
    font-size: 15px !important;
  } */
}

@media (min-width: 567px) and (max-width: 769px) {
  .get-otp-btn {
    width: 63%;
    margin: 4% auto;
    margin-left: 37px;
  }
  .input-group {
    position: relative;
    display: inline-table;
    border-collapse: separate;
    width: 60%;
    margin: auto;
    margin-left: 62px;
  }
  /* input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    border: 1px solid #ccc;
    margin: 0;
    font-size: 15px !important;
  } */
}

/* @media (min-width: 992px) and (max-width: 1200px) {
  input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    border: 1px solid #ccc;
    margin: 0;
    font-size: 15px !important;
  }
} */

@media (min-width: 1200px) and (max-width: 1600px) {
  .prefix input {
    border-radius: 0px 4px 4px 0px;
    font-size: 15px !important;
  }
  /* input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    border: 1px solid #ccc;
    margin: 0;
    font-size: 14px !important;
  } */
}
@media (min-width: 320px) and (max-width: 376px) {
  .loginPopUpLanding {
    margin-left: 4%;
  }
  .Favourite_Badge_ProductListing {
    top: 18% !important;
    width: 12% !important;
    height: 6.5% !important;
    right: 8% !important;
  }
}
@media (min-width: 376px) and (max-width: 400px) {
  .loginPopUpLanding {
    margin-left: -19%;
    width: 126%;
  }
}
@media (min-width: 400px) and (max-width: 450px) {
  .loginPopUpLanding {
    margin-left: -8%;
  }
}
@media (min-width: 480px) and (max-width: 530px) {
  .loginPopUpLanding {
    margin-left: -6.5%;
    width: 123%;
  }
}
@media (min-width: 480px) and (max-width: 576px) {
  .loginPopUpLanding {
    margin-left: -3%;
    width: 127%;
  }
}
